.footer {
    background-color: #8686861a;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    color: #fff;
    width: 100%;
    overflow-x: hidden;
    position: relative;
  }
  
  .social-links {
    margin-bottom: 10px;
    margin-top: 10px;
  }
  
  .social-links a {
    display: inline-block;
    margin: 0 10px;
    color: #fff;
  }
  
  .social-links a:hover {
    color: #8c00ff;
  }
  
  .social-links .social-icon {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
  
  .footer-text {
    font-size: 18px;
    margin-top: 10px;
  }
  
  @media (max-width: 576px) {
    .footer {
      padding: 10px 0;
    }

    .footer-text {
      font-size: 16px;
      margin-top: 0;
    }

    .social-links .social-icon {
      margin-bottom: 0;
      }

    .social-links a {
      margin: 0 5px;
    }
  }
  