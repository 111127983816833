.home {
  position: relative;
  overflow: hidden;
  min-height: 100vh;
}

.home-content {
  max-width: 800px;
  padding-top: 80px;
  margin: 0 auto;
}

#tsparticles {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.text {
  margin-top: 40px;
}

.home-circle {
  width: 250px;
  height: 250px;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 auto;
}

.home-circle img {
  width: 100%;
  height: auto;
}

.text-one {
  font-size: 2rem;
  font-weight: bold;
  color: #fff;
  margin-top: 20px;
}

.text-two {
  font-size: 3rem;
  font-weight: bold;
  color: #8c00ff;
  margin-top: 10px;
}

.text-three {
  margin-top: 10px;
  margin-bottom: 10px;
  color: #8c00ff;
  font-size: 45px;
  font-weight: 900;
}

.pre-style {
  display: inline-block;
  width: 10px;
  height: 100%;
  background-color: #8c00ff;
  animation: blink 1s infinite;
}

@keyframes blink {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}

.social-row {
  position: fixed;
  bottom: 10px;
  width: 100vw;
  margin-right: 0;
  margin-left: 0;
}

.social-col {
  text-align: center;
}

.social-links {
  margin-top: 20px;
  text-align: center;
}

.social-links a {
  display: inline-block;
  margin: 0 10px;
  color: #ffffff;
}

.social-links a:hover {
  color: #8c00ff;
}

.social-links .social-icon {
  font-size: 1.5rem;
}

@media (max-width: 768px) {
  .home-circle {
    margin-top: 50px;
  }
  .text-two {
    font-size: 4rem;
    margin-top: 0 !important;
  }
  .text-three {
    font-size: 2rem;
    margin-top: 0 !important;
    margin-bottom: 10px;
  }
}