.about-container {
    color: #ffffff;
    padding-top: 90px;
    overflow-x: hidden;
    
}

.container-fluid{
  padding-left: 0;
  padding-right: 0;
}
  
.profile-image {
    max-width: 100%;
    border-radius: 50%;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
}
  
.right {
    padding: 15px;
}
  
.about-title {
    font-size: 3rem;
    font-weight: bold;
    margin-bottom: 30px;
}
  
  .about-topic {
    font-size: 2rem; 
    font-weight: bold;
    margin-bottom: 20px;
  }
  
.about-p {
    font-size: 1.2rem;
    line-height: 1.6;
    margin-bottom: 15px;
}
  
.resume-buttons .btn {
    margin-right: 10px;
    margin-left: 10px; 
}
  
.resume-buttons .btn:hover {
    background-color: #ffffff;
    color: #2c3e50; 
}
  
@media (max-width: 768px) {
    .about-p {
      padding: 20px;
      text-align: justify;
    }
}