@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@500&display=swap");
body {
  margin: 0;
  font-family: "Raleway", serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-image: linear-gradient(to left, rgb(27 20 41), rgb(20 15 35)); */
  background-image: linear-gradient(to left, rgb(0, 0, 20), rgb(0, 0, 20));

}

.star-background {
  width: 100%;
  height: 100%;
  position: fixed;
  inset: 0;
  z-index: -1;
}
