.projects-container {
  padding-top: 120px;
  color: #ffffff;
  text-align: center;
  width: 100%;
  overflow-x: hidden;
  margin: 0 auto;
}

.projects-title {
  margin-bottom: 1.5rem;
  font-size: 3rem;
  font-weight: bold;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.project-row {
  padding: 0 1rem;
  margin: 0;
}

.project-card {
  border: none;
  width: 100%;
  border-radius: 30px;
  background-color: transparent;
  overflow: hidden;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  box-shadow: 0 4px 5px 3px rgba(119, 53, 136, 0.459) !important;
  backdrop-filter: blur(1px);
  -webkit-backdrop-filter: blur(1px);
  margin-bottom: 2rem;
}

.project-card:hover {
  box-shadow: 0 4px 4px 5px rgba(129, 72, 144, 0.561) !important;
  transform: translateY(-5px);
}

.project-card .card-img-top {
  padding: 20px;
  border-radius: 30px;
  height: 250px;
}

.project-card .card-body {
  padding: 1.5rem;
  color: #ffffff;
}

.project-card .card-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.project-card .card-text {
  margin-bottom: 10px;
  text-align: justify;
}

.project-card .btn {
  margin-top: 10px;
  background-color: #370075;
  color: #ffffff;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  padding: 0.75rem 1.5rem;
  transition: all 0.3s ease;
}

.project-card .btn:hover {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transform: translateY(-2px);
}

@media (max-width: 767px) {
  .projects-title {
    font-size: 2rem;
  }

  .project-row {
    padding: 0 0.5rem;
  }

  .project-card {
    width: 100%;
    margin-bottom: 1rem;
  }
}
