html, body {
  overflow-x: hidden !important;
}

.contact-container {
  padding: 80px 0;
  color: #ffffff;
  text-align: center;
  width: 100%;
  overflow-x: hidden;
  margin: 0 auto;
}

.contact-form h2 {
  font-size: 3rem;
  font-weight: 900;
  margin-bottom: 20px;
  color: #fff;
  text-align: center;
}

.contact-svg {
  width: 100%;
  max-width: 500px;
  height: auto;
}

.contact-form {
  padding: 0 20px;
}

.contact-form .mb-3 {
  margin-bottom: 20px;
}

.form-control {
  background-color: transparent;
  backdrop-filter: blur(1px);
  -webkit-backdrop-filter: blur(1px);
  color: #fff;
  border-radius: 5px;
  padding: 10px 15px;
}

.form-control:focus {
  background-color: rgba(0, 0, 0, 0.342);
  backdrop-filter: blur(1px);
  -webkit-backdrop-filter: blur(1px);
  color: #fff;
  border-radius: 5px;
  padding: 10px 15px;
}

.form-control::placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.button-submit {
  background-color: rgb(0, 0, 98);
  color: #fff;
  border-radius: 10px;
  padding: 10px 15px;
  border-color: rgb(0, 0, 151);
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  display: block;
  margin: 0 auto;
}

.button-submit:hover {
  background-color: rgb(0, 0, 61);
  box-shadow: 0 6px 12px rgb(38, 38, 157);
  transform: translateY(-2px);
  transition: all 0.3s ease-in-out;
}

@media (max-width: 768px) {
  .contact-form h2 {
    font-size: 2rem;
  }
  .contact-form {
    text-align: center;
  }
  .contact-form .form-control {
    width: 90%;
    margin: 0 auto 20px;
  }
}
