.resume-container {
    padding: 120px 0;
    color: #ffffff;
    text-align: center;
  }
  
  .resume-title {
    margin-bottom: 30px;
    font-size: 3rem;
    font-weight: bold;
  }
  
  .resume-iframe {
    width: 80%;
    height: 600px;
    border: none;
    margin-bottom: 20px;
  }
  
  .resume-buttons .btn {
    margin-top: 20px;
  }
  
  .resume-buttons .btn:hover {
    background-color: #ffffff;
    color: #2c3e50; 
  }
  