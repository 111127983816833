.navbar {
  margin-bottom: 20px;
  /* box-shadow: 0 4px 6px rgb(14, 0, 70); */
}

.navbar-brand {
  font-size: 1.5rem;
  font-weight: bold;
  display: flex;
  align-items: center;
}

.navbar-brand img {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.nav-icon {
  margin-right: 5px;
  font-size: 1.2rem;
  color: #8c00ff;
}

.nav-link {
  color: #ffffff !important;
  transition: all 0.3s ease;
  font-size: 1.2rem;
  /* margin-left: 25px; */
  position: relative;
  font-weight: bold;
  padding: 10px 15px;
  border-radius: 5px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}

.nav-link:hover {
  background-color: rgba(255, 255, 255, 0.1);
  /* transform: scale(1.1); */
  text-shadow: none;
}

.nav-link::before {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #9307ff;
  transform: scaleX(0);
  transform-origin: bottom right;
  transition: transform 0.3s ease;
}

.nav-link:hover::before {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.navbar.fixed-top {
  position: fixed;
  width: 100%;
  z-index: 1000;
}

.navbar-nav {
  flex-direction: row;
}

.nav-item {
  margin-left: 15px;
}

@media (max-width: 768px) {
  .navbar-nav {
    flex-direction: column;
    text-align: center;
    background-color: rgba(27, 20, 41, 0.9);
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  }

  .nav-item {
    margin-bottom: 10px;
  }

  .close-button {
    padding: 0;
    background-color: transparent;
    border: none;
    z-index: 1001;
  }
  
  .close-icon {
    font-size: 2rem;
    color: #ffffff;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
  }

  .navbar-collapse {
    position: absolute;
    top: 56px;
    left: 0;
    right: 0;
    z-index: 999;
    transition: all 0.3s ease;
    transform: translateY(-100%);
    opacity: 0;
  }

  .navbar-collapse.show {
    transform: translateY(0);
    opacity: 1;
  }
}

.navbar-toggler {
  border: none;
  outline: none !important;
}

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=UTF8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba%2880,0,255,1%29' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}